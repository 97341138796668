/* eslint-disable */
import React from "react";

// Utils
import classNames from "classnames";

// Icons
import { FiList } from "react-icons/fi";

// Components
import { Button } from "components/core/button";

// Hooks
import { useTranslation } from "react-i18next";
import { useActionsPlan } from "hooks/useActionsPlan";

export function ActionsPlan({ student, actionsPlan, setActionsPlan, className, loading }) {
    const { t } = useTranslation("common");
    const { comments } = actionsPlan;
    const actionsOrderedByDate = comments && comments.sort((a, b) => new Date(a?.displayDate) - new Date(b?.displayDate));
    const upcomingActions = actionsOrderedByDate && actionsOrderedByDate.filter(({ displayDate }) => displayDate >= new Date().toLocaleDateString());
    const daysToNextAction = upcomingActions && upcomingActions.length > 0 ? Math.ceil((new Date(upcomingActions[0].displayDate) - new Date()) / (1000 * 60 * 60 * 24)) : null;
    const nextAction = upcomingActions?.length > 0 && upcomingActions[0];
    const {display, setOnUpdate, setHighlight} = useActionsPlan();
    const handleDisplay = ({highlight}) => {
        setOnUpdate?.(()=>setActionsPlan);
        setHighlight?.(highlight);
        display?.({student});
    };
    if (loading) return <div className={className}></div>
    return <div className={classNames(className, "flex items-center gap-2")}>
        <Button color="default" size={"icon"} className={"!p-1.5 !text-base h-fit"} onClick={handleDisplay}>
            <FiList />
        </Button>
        {(daysToNextAction || daysToNextAction === 0) ? <div className="flex flex-col items-center justify-between">
            <Button color="hiddenLink" onClick={()=>handleDisplay({highlight:nextAction?.id})} className="flex items-center gap-1">
                {daysToNextAction > 0 ? `${daysToNextAction} ${t("days")}` : t("today")}
            </Button>
            <span className="text-gray-500 text-xs">{t("next-action")}</span>
        </div>
            : <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <span className="text-gray-500">-</span>
                </div>
            </div>
        }
    </div>
}

export function ActionsCompletedRecently({ actionsPlan, className, loading }) {
    const { comments } = actionsPlan;
    const numberOfRecentDays = 14;
    const recentlyCompletedActions = comments?.filter(({ completed, completedAt }) => completed && (new Date() - new Date(completedAt)) / (1000 * 60 * 60 * 24) <= numberOfRecentDays);
    if (loading || recentlyCompletedActions?.length <= 0) return <div className={className}></div>
    return <div className={classNames(className, "")}>
        {recentlyCompletedActions?.length}
    </div>
}