/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';

// Components
import { Info } from 'components/core/info';
import { Skeleton } from 'components/core/skeleton';
import { Editor } from 'components/core/rich-editor/editor';
import { SelectDate } from 'components/tools/select_date';
import { Viewer } from 'components/core/rich-editor/viewer';
import { Dropdown } from 'components/core/dropdown';
import { Button } from 'components/core/button';
import { SlideOver } from 'components/core/slide_over';
import { CardSectionHeading } from 'components/core/typo';
import { CheckBox } from 'components/tools/checkbox';
// Icons
import { FiEdit2, FiTrash, FiCheck, FiSettings } from 'react-icons/fi';

// API
import { getOrCreateActionsPlan, createComment, updateComment, deleteComment, updateNote, toggleCommentCompletion } from 'api/notes';

// Utils
import { locale } from 'utils/locale';
const fullDayFormat = locale.format("%A, %d %B %Y");
import classNames from 'classnames';

// Hooks
import { useAPI } from 'hooks/useAPI';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useActionsPlan } from 'hooks/useActionsPlan';

export function ActionsPlanSlideOver({ student,  open, setOpen, onUpdate }) {
    const { t } = useTranslation("common");
    return <SlideOver size='lg' open={open} setOpen={setOpen}>
        <div className='px-5'>
            <CardSectionHeading title={t("actions-plan")} />
            <ActionsPlan student={student} onUpdate={onUpdate} />
        </div>
    </SlideOver>
}

export function ActionsPlan({ student }) {
    const { t } = useTranslation("common");
    const { ni } = useParams();
    const params = useMemo(() => ({ student: student || ni }), []);
    const [plan, { loading, setResult: setPlan }] = useAPI(getOrCreateActionsPlan, params, { camelize: true });
    const [, { execute: executeUpdateNote }] = useAPI(updateNote, { noteId: plan?.id }, { camelize: true, immediate: false });
    const { comments: actions } = plan || {};
    const {onUpdate} = useActionsPlan();

    const [filter, setFilter] = useLocalStorage("actions-plan-filters", {
        past: true,
        completed: true
    });

    const applyFilters = (actionsList) => {
        if (!actionsList) return [];
        return actionsList.filter(action => {
            const isPast = action?.displayDate < new Date().toLocaleDateString();
            const isCompleted = action.completed;
            if (!filter.past && isPast) return false;
            if (!filter.completed && isCompleted) return false;
            return true;
        });
    };

    const filteredActions = useMemo(() => applyFilters(actions), [actions, filter]);
    const sortedActions = useMemo(() => filteredActions.sort((a, b) => a.displayDate > b.displayDate ? 1 : -1), [filteredActions]);

    const handleNewComment = (comment) => {
        if (!plan?.comments || plan?.comments.length === 0) executeUpdateNote({ updateAuthor: true });
        setPlan((prev) => ({ ...prev, comments: [...prev.comments, comment] }));
        setShowAddAction(false);
    };

    const handleDeleteComment = (comment) => {
        setPlan((prev) => ({ ...prev, comments: prev?.comments?.filter(({ id }) => id !== comment?.id) }));
    };

    const handleUpdateComment = (comment) => {
        setPlan((prev) => ({ ...prev, comments: prev?.comments?.map((c) => c.id === comment.id ? comment : c) }));
    };
    
    useEffect(() => {
        plan && onUpdate?.(plan);
    }, [plan, onUpdate]);

    const [showAddAction, setShowAddAction] = useState(false);
    if (loading) return <Skeleton.List numElements={1} className="space-y-3" itemClassName="h-36" />;

    return <>
        <div className='@container'>
            <div className='flex flex-col @[700px]:flex-row gap-4'>
                <ActionPlanStats actions={actions} student={plan?.student} />
                <div className='w-full mt-5'>
                    <div className='flex justify-end'>
                        <Button color="default" size="sm" onClick={() => setShowAddAction(prev => !prev)}>
                            {!showAddAction ? t("add-action") : t("cancel")}
                        </Button>
                    </div>
                    {showAddAction && <div className='border-b pb-4 mb-2 mt-2'><AddAction planId={plan?.id} onResult={handleNewComment} /></div>}
                    <div className='flex gap-2 items-center my-1'>
                        <label className="text-sm uppercase text-gray-500">{t("planned-actions")}</label>
                        <ActionsFilter filter={filter} setFilter={setFilter} />
                    </div>
                    {sortedActions && sortedActions.length > 0 ?
                        <div className='flex flex-col gap-2'>
                            {sortedActions.map((action) => {
                                return <Action
                                    key={action?.id}
                                    action={action}
                                    onDelete={handleDeleteComment}
                                    onUpdate={handleUpdateComment}
                                />;
                            })}
                        </div>
                        : <div className='text-gray-500 text-sm bg-gray-100 p-3 rounded-md'>{t("no-next-action")}</div>
                    }
                </div>
            </div>
        </div>
    </>;
}

export function ActionPlanStats({ actions, student }) {
    const { t } = useTranslation("common");
    const latestComment = actions?.reduce((acc, curr) => acc.createdAt > curr.createdAt ? acc : curr, actions[0]);
    const sortedActions = actions?.sort((a, b) => new Date(a.displayDate) - new Date(b.displayDate));
    const upcomingActions = sortedActions?.filter(({ displayDate }) => displayDate >= new Date().toLocaleDateString());
    const daysToNextAction = upcomingActions?.length > 0 ? Math.ceil((new Date(upcomingActions[0].displayDate) - new Date()) / (1000 * 60 * 60 * 24)) : 0;
    const completedActions =  actions?.filter(({ completed }) => completed);
    const completionPercentage = actions?.length > 0 ? Math.round((completedActions.length / actions.length) * 100) : 0;
    const numberOfRecentDays = 14;
    const recentlyCompletedActions = actions?.filter(({ completed, completedAt }) => completed && (new Date() - new Date(completedAt)) / (1000 * 60 * 60 * 24) <= numberOfRecentDays);
    return (
        <Info.Container label={"Info"} className='w-full @[700px]:min-w-72'>
            <Info.Field value={student?.name} label={t("student")}/>
            <Info.Field value={latestComment ? new Date(latestComment.createdAt).toLocaleDateString() : null} label={t("last-updated")} noValueLabel={t("no-update")} />
            <Info.Field
                value={upcomingActions?.length > 0 ? daysToNextAction === 0 ? t("today") : `${daysToNextAction} ${t("days")}` : null}
                label={t("next-action-planned")}
                noValueLabel={t("no-next-action")}
            />
            {actions?.length > 0 && (
                <Info.Field
                    value={`${completedActions?.length}/${actions?.length} (${completionPercentage}%)`}
                    label={t("completed-actions")}
                    noValueLabel={t("no-completed-action")}
                />
            )}
            {actions?.length > 0 && (
                <Info.Field
                    value={recentlyCompletedActions?.length || t("none_female")}
                    label={t("completed-in-last-x-days", { days: numberOfRecentDays })}
                    noValueLabel={t("no-completed-action")}
                />
            )}
        </Info.Container>
    );
}


export function ActionsFilter({ filter, setFilter }) {
    const { t } = useTranslation("common");
    const hasFilter = !filter?.past || !filter?.completed;
    const handleUpdate = (key, value) => {
        setFilter(prev => ({ ...prev, [key]: value }))
    }
    return <>
        <Dropdown
            onlyIcon={
                <FiSettings className='size-3.5' />
            }
            color={hasFilter ? "activeColor" : "default"}
            orientation="left"
            menuItemsClassName="!w-[200px]"
            iconClassName="!p-1.5"
        >
            <div className="p-2">
                <div className='flex items-center justify-between'>
                    <p className='text-sm text-gray-500'>{t("actions-filters.past")}</p>
                    <CheckBox value={filter?.past} setValue={(v) => handleUpdate("past", v)} />
                </div>
                <div className='flex items-center justify-between'>
                    <p className='text-sm text-gray-500'>{t("actions-filters.completed")}</p>
                    <CheckBox value={filter?.completed} setValue={(v) => handleUpdate("completed", v)} />
                </div>
            </div>
        </Dropdown>
    </>
}

export function Action({ action, onUpdate, onDelete}) {
    const { t } = useTranslation("common")
    const { displayDate, content: defaultContent, id } = action || {};
    const formattedDate = fullDayFormat(new Date(`${displayDate}T00:00:00`));
    const [modify, setModify] = useState(false);
    const [content, setContent] = useState(defaultContent);
    const [date, setDate] = useState(new Date(`${displayDate}T00:00:00`));
    const isEmpty = !content || content?.replace(/<[^>]*>?/gm, '').trim() === ''
    const location = useLocation();
    const hash = location.hash;
    const hashValue = hash ? hash.substring(1) : null;
    const {highlight} = useActionsPlan();
    const isHighlighted = highlight === action?.id || hashValue === id;
    // SAVE
    const handleSave = () => {
        setModify(false);
        onUpdate?.({ ...action, content, displayDate: date.toLocaleDateString() });
    }
    const [, { loading: saving, execute: save }] = useAPI(updateComment, { id: action?.id, comment: content, displayDate: date }, { camelize: true, immediate: false, onResult: handleSave });

    // DELETE
    const handleDelete = () => {
        setModify(false);
        onDelete?.(action);
    }
    const [, { loading: deleting, execute: deleteAction }] = useAPI(deleteComment, { id: action?.id }, { camelize: true, immediate: false, onResult: handleDelete });

    // CANCEL
    const handleCancel = () => {
        setModify(false);
        setContent(defaultContent);
        setDate(new Date(`${displayDate}T00:00:00`));
    }

    useEffect(() => {
        if (isHighlighted) {
            const element = document.getElementById(id);
            element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [isHighlighted, id]);

    return <>
        <div id={id} className="group">
            <div className={classNames('bg-gray-100 p-2 px-2.5 rounded-md border-2 border-gray-100', isHighlighted && "border-2 border-orange-500")}>
                <div className='flex justify-between'>
                    {!modify ? <div className="">
                        <label className='text-gray-500 text-sm mb-0.5 block'>{formattedDate}</label>
                    </div>
                        :
                        <div className='mb-1'>
                            <SelectDate orientation="left" label={t("due-date")} value={date} setValue={setDate} />
                        </div>
                    }
                    <div className="flex gap-2 items-start">
                        {
                            !modify && <div className='opacity-0 group-hover:opacity-100 transition-opacity -mt-0.5'>
                                <Button color="default" size="smToolbarIcon" className="!py-0.5" onClick={() => setModify(true)}>
                                    <FiEdit2 className='size-3 mr-1' /><span className='text-sm'>{t("edit")}</span>
                                </Button>
                            </div>
                        }
                        <ToggleActionCompletion action={action} setAction={onUpdate} />
                    </div>
                </div>
                {
                    modify ? <>
                        <Editor id={action?.id} toolbarStyles='!bg-gray-200' editorStyles='border-2 rounded-b-md' text={content} setText={setContent} />
                        <div className='flex justify-between items-center'>
                            <Dropdown
                                onlyIcon={<div className='flex items-center py-1'>
                                    <FiTrash className='text-red-500 size-3 shrink-0' />
                                    <span className='text-red-500 ml-1 text-xs leading-none block'>{t("delete")}</span>
                                </div>
                                }
                                color={"danger"}
                                menuItemsClassName="text-xs"
                                orientation="left"
                            >
                                <Dropdown.Item
                                    icon={<FiTrash className='shrink-0' />}
                                    name={t("confirm-delete")}
                                    color="danger"
                                    className="!w-60"
                                    onClick={deleteAction}
                                />
                            </Dropdown>
                            <div className='flex gap-1 mt-2'>
                                <Button color="default" size="xs" onClick={handleCancel}>
                                    {t("cancel-short")}
                                </Button>
                                <Button color="active" size="xs" loading={saving || deleting} disabled={isEmpty || saving || deleting} onClick={save}>
                                    {t("save")}
                                </Button>
                            </div>
                        </div>
                    </>
                        :
                        <>
                            <Viewer className='whitespace-pre-wrap' html={defaultContent} />
                        </>
                }
            </div>
        </div>
    </>
}

export function ToggleActionCompletion({ action, setAction }) {
    const [, { execute, loading }] = useAPI(toggleCommentCompletion, { id: action?.id }, { immediate: false, camelize: true });
    const { completed } = action || {};
    const handleToggle = () => {
        if (loading) return;
        execute();
        setAction?.({ ...action, completed: !completed, completedAt: completed ? null : new Date().toISOString() });
    }
    return <>
        <div onClick={handleToggle} className={classNames("size-5 cursor-pointer rounded opacity-80 hover:opacity-100 transition-all flex items-center justify-center", completed ? "bg-emerald-500" : "bg-gray-300")}>
            {
                completed ? <FiCheck className="text-white size-4" /> :
                    <FiCheck className="text-gray-400 size-4 transition-all" />
            }
        </div>
    </>
}

export function AddAction({ planId, onResult }) {
    const { t } = useTranslation("common")
    const defaultAction = {
        noteId: planId,
        commentDisplayDate: new Date(),
        content: "",
    };
    const [action, setAction] = useState(defaultAction);
    const isEmpty = !action?.content || action?.content?.replace(/<[^>]*>?/gm, '').trim() === ''
    const reset = () => setAction(defaultAction);
    const handleResult = ({ comments }) => {
        const latestComment = comments.reduce((acc, curr) => acc.id > curr.id ? acc : curr, comments[0]);
        onResult?.(latestComment);
        reset();
    }
    const [, { loading, execute }] = useAPI(createComment, action, { camelize: true, immediate: false, onResult: handleResult });
    const handleSubmit = () => {
        execute();
    }
    return <>
        <div className=''>
            <Editor
                toolbarStyles='p-2 mb-0.5'
                editorStyles="text-black"
                minHeight={"100px"}
                id={"add-action"}
                text={action?.content}
                setText={(v) => setAction({ ...action, content: v })}
                hideToolbarToggle
            />
            <div className='flex flex-col gap-1 lg:flex-row justify-between mt-1'>
                <div>
                    <SelectDate orientation="left" label={t("due-date")} value={action.commentDisplayDate} setValue={(v) => setAction({ ...action, commentDisplayDate: v })} />
                </div>
                <Button loading={loading} disabled={loading || isEmpty} color="active" size="sm" onClick={handleSubmit}>{t("submit-action")}</Button>
            </div>
        </div>
    </>
}