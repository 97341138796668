import { fetchCall } from 'api';
import { isString, isBool} from 'utils/var_check';

/*
  Search notes
*/
export function searchNotes({student,
                        team,
                      tutor,
                      pairing,
                      studySession,
                      author,
                      isOpen,
                      hasActivePairing,
                      shouldDiscuss,
                      preset,
                      code,
                      codes,
                      group,
                      fromDate,
                      toDate,
                      document,
                      page,
                      pairingAssignedTo,
                      limit,
                      returnCount,
                      orderBy,
                      orderDirection}){
  const options = {
    method: "GET",
    endpoint: returnCount? "/count-notes":"/notes",
    query: {author,
            codes,
            team: team && (isString(team)? team: team.slug),
            student: student && (isString(student)? student: student.ni),
            studySession: studySession && (isString(studySession)? studySession: studySession.id),
            tutor: tutor && (isString(tutor)? tutor: tutor.ni),
            code: code && (isString(code)? code: code.code),
            isOpen: isOpen && (isBool(isOpen)? isOpen: isOpen.value),
            hasActivePairing: hasActivePairing && (isBool(hasActivePairing)? hasActivePairing: hasActivePairing.value),
            shouldDiscuss: shouldDiscuss && (isBool(shouldDiscuss)? shouldDiscuss: shouldDiscuss.value),
            group: group && (isString(group)? group: group.group),
            pairing: pairing && (isString(pairing)? pairing: pairing.id),
            fromDate: fromDate && new Date(fromDate).toISOString(),
            toDate: toDate && new Date(toDate).toISOString(),
            preset: preset && (isString(preset)? preset: preset.id),
            pairingAssignedTo: pairingAssignedTo && (isString(pairingAssignedTo)? pairingAssignedTo: pairingAssignedTo.email),
            page,
            document,
            limit,
            orderBy,
            orderDirection}
  }
  return fetchCall(options)
}

export const countNotes = (params) => {
  return searchNotes({...params, returnCount:true})
};

/*
  Search noteCodes
*/
export const searchNoteCode = ({search, group, special, page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET",
    endpoint: "/note-codes",
    query: {search,
            group: group && (isString(group)? group: group.group),
            special,
            page,
            limit,
            orderBy,
            orderDirection}
  }
  return fetchCall(options)
};

/*
    Retrieve noteCode
*/
export const retrieveNoteCode = ({code}) => {
    const options = {
      method: "GET",
      endpoint: `/note-codes/${code}`,
      query: {}
    }
    return fetchCall(options)
  };

/*
  Search note grouped by date/student
*/
export function searchGroupedNotes({team,
                              preset,
                            fromDate,
                            toDate,
                            code,
                            group,
                            special,
                            documentGroup,
                            period,
                            student,
                            page,
                            limit,
                            orderBy,
                            orderDirection}){
  const options = {
    method: "GET",
    endpoint: "/grouped-notes",
    query: {student: student && (isString(student)? student: student.ni),
            team: team && (isString(team)? team: team.slug),
            preset: preset && (isString(preset)? preset: preset.id),
            noteCode: code && (isString(code)? code: code.code),
            fromDate: fromDate && fromDate.toISOString(),
            toDate: toDate && toDate.toISOString(),
            group: group && (isString(group)? group: group.group),
            documentGroup: documentGroup && (isString(documentGroup)? documentGroup: documentGroup.code),
            period: period && (isString(period)? period: period.slug),
            special,
            page,
            limit,
            orderBy,
            orderDirection}
  }
  return fetchCall(options)
}

export function getMeetingStatistics({team,
                        preset,
                        documentGroup, 
                        period}){
  const options = {
    method: "GET",
    endpoint: "/statistics-rencontres",
    query: {team: team && (isString(team)? team: team.slug),
            preset: preset && (isString(preset)? preset: preset.id),
            documentGroup: documentGroup && (isString(documentGroup)? documentGroup: documentGroup.code),
            period: period && (isString(period)? period: period.slug)}
  }
  return fetchCall(options)
}

/*
  Search note groups
*/
export const searchNoteGroups = ({search, page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET",
    endpoint: "/note-groups",
    query: {search,
            page,
            limit,
            orderBy,
            orderDirection}
  }
  return fetchCall(options)
};

/*
  Retrieve note
*/
export const retrieveNote = ({noteId}) => {
  const options = {
    method: "GET",
    endpoint: `/notes/${noteId}`
  }

  return fetchCall(options)
};


/*
  Update note
*/
export const updateNote = ({noteId, student, code, openedAt, closedAt, shouldDiscuss, updateAuthor}) => {
  const options = {
    method: "POST",
    endpoint: `/notes/${noteId}`,
    body: {student, code, openedAt, closedAt, shouldDiscuss, updateAuthor}
  }
  return fetchCall(options)
};

/*
  Update note
*/
export const deleteNote = ({noteId}) => {
  const options = {
    method: "DELETE",
    endpoint: `/notes/${noteId}`,
  }
  return fetchCall(options)
};

/*
  Create an note
*/
export function createNote({ student, 
                  tutor, 
                  coach, 
                  pairing, 
                  minutes, 
                  studySession, 
                  commentDisplayDate, 
                  code, 
                  openedAt, 
                  documentGroup,
                  period,
                  closedAt, 
                  shouldDiscuss, 
                  comment, 
                  commentCodes }){
  const options = {
    method: "POST",
    endpoint: `/notes`,
    body: {tutor: tutor && (isString(tutor)? tutor: tutor.ni),
          student: student && (isString(student)? student: student.ni),
          studySession: studySession && (isString(studySession)? studySession: studySession.id),
          pairing: pairing && (isString(pairing)? pairing: pairing.id),
          coach: coach && (isString(coach)? coach: coach.ni),
          code: code && (isString(code)? code: code.code),
          minutes,
          commentDisplayDate: commentDisplayDate && new Date(commentDisplayDate).toISOString().slice(0,10),
          documentGroup: documentGroup && (isString(documentGroup)? documentGroup: documentGroup.code),
          period: period && (isString(period)? period: period.slug),
          shouldDiscuss,
          commentCodes,
          openedAt,
          closedAt,
          comment}
  }
  return fetchCall(options)
}

/*
  Create a comment
*/
export const createComment = ({ noteId, closeOnComment, minutes, commentDisplayDate, content, codes })=>{
  const options = {
    method: "POST",
    endpoint: `/notes/${noteId}/add-comment`,
    body: {comment:content, 
      minutes,
      displayDate: commentDisplayDate && new Date(commentDisplayDate).toISOString().slice(0,10),
      closeOnComment, codes}
  }
  return fetchCall(options)
}

/*
  Update an comment
*/
export const updateComment = ({ id, comment, minutes, displayDate})=>{
  const options = {
    method: "POST",
    endpoint: `/comments-on-note/${id}`,
    body: {content: comment,minutes, displayDate: displayDate && new Date(displayDate).toISOString().slice(0,10)}
  }
  return fetchCall(options)
}

/*
    Toggle completion of a comment
*/
export const toggleCommentCompletion = ({ id })=>{
    const options = {
        method: "POST",
        endpoint: `/comments-on-note/${id}/toggle-completion`
    }
    return fetchCall(options)
}

/*
  Delete a comment
*/
export const deleteComment = ({ id })=>{
  const options = {
    method: "DELETE",
    endpoint: `/comments-on-note/${id}`
  }
  return fetchCall(options)
}


/*
  Get all note types
*/
export const searchCommentTypes = ({search, orderBy, orderDirection})=>{

  const options = {
    method: "GET",
    endpoint: "/comment-codes",
    query: {search, orderBy, orderDirection}
  }
  return fetchCall(options)
}

/*
  Delete comment type on note
*/
export const deleteCommentTypeOnNote = ({ commentId, codeId })=>{
  const options = {
    method: "DELETE",
    endpoint: `/comments-on-note/${commentId}/code/${codeId}`
  }
  return fetchCall(options)
}

/*
  Add comment type on note
*/
export const addCommentTypesOnNote = ({ commentId, codes })=>{
  const options = {
    method: "POST",
    endpoint: `/comments-on-note/${commentId}/codes`,
    body: {codes}
  }
  return fetchCall(options)
}

export const createCommentType = ({ name, description, color, group })=>{
  const options = {
    method: "POST",
    endpoint: `/comment-codes`,
    body: {name, description, color, group}
  }
  return fetchCall(options)
}

export const updateCommentType = ({ code, name, description, color, group })=>{
  const options = {
    method: "POST",
    endpoint: `/comment-codes/${code}`,
    body: {name, description, color, group}
  }
  return fetchCall(options)
}

export const deleteCommentType = ({ code })=>{
  const options = {
    method: "DELETE",
    endpoint: `/comment-codes/${code}`
  }
  return fetchCall(options)
}

export const getOrCreateActionsPlan = ({student})=>{
    const options = {
        method: "GET",
        endpoint: `/notes/action-plan`,
        query: {
            student: student && (isString(student)? student: student.ni)
        }
    }
    return fetchCall(options)
}