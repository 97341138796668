import React, { useState } from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { SelectWeeklyAvailabilities } from 'components/tools/select_weekly_availabilities';
import { SelectPreferences, SelectSubjectPreferencesAuto } from 'components/tools/select_preferences';
import { Info } from 'components/core/info';
import { InfoSectionHeading, RawTextWarning } from 'components/core/typo';
import { CheckBox } from 'components/tools/checkbox';
import { LargeLabel, ModifiableSelectField, SubLabel } from 'components/tools/modifiable_fields';
import { Error } from 'components/core/typo'

// Icons
import { FiUsers, FiWifi } from 'react-icons/fi';

// Utils
import classnames from 'classnames';

// Contexts
import { StudentContext } from 'contexts/student';

// Hooks
import { useContext, useCallback } from 'react';
import { useMemoCompare } from 'hooks/useMemoCompare';
import { useAPI } from 'hooks/useAPI';
import { useTranslation } from 'react-i18next';


// API
import { updateStudent, retrieveSubjectPreferences, updateSubjectPreferences } from 'api/students';
import { locale, dateParse } from 'utils/locale';
import { SelectDate } from 'components/tools/select_date';
import { getCurrentPeriod, getFullCurrentPeriod } from 'utils/period';
import { SelectPeriods } from 'components/tools/select_period';
import { Button } from 'components/core/button';
import { Modal, ModalBasic } from 'components/core/modal';
import { BellAlertIcon } from '@heroicons/react/24/outline';
import { FiCheck } from 'react-icons/fi';
const baseDayFormat = locale.format("%d %B %Y");
const baseDayFormat2 = locale.format("%d %b.");


export const subjectPreferenceOptions = {
    "none": { name: "Aucun besoin", previous: null, next: "low", qte: 0 },
    "low": { name: "Faiblement prioriraire", previous: "none", next: "high", qte: 1 },
    "high": { name: "Prioriraire", previous: "low", next: null, qte: 2 },
}

export function SelectDraftSubjectPreferences({ value, setValue }) {
    const { t } = useTranslation('common');
    const handleChange = useCallback(({ name, slug, description }) => {
        setValue(value.map(d => d.slug !== slug ? d : ({ name, slug, description })))
    }, [value]);

    return <Info.Container modify={true} className="my-8">
        <InfoSectionHeading mediumTitle={t('subject-preferences')} description={t('student-subject-preferences-description')} />
        <SelectSubjectPreferencesAuto
            values={value || []}
            setValues={setValue}
            setValue={handleChange}
            formatValue={(d) => d.name}
            options={subjectPreferenceOptions}
            defaultOption={subjectPreferenceOptions.none} />
    </Info.Container>
}


function SelectSubjectPreferences({ ni }) {
    const { t } = useTranslation('common');
    const params = useMemoCompare({ ni }, (prev, next) => prev && prev.ni === next.ni)
    const [value, { setResult }] = useAPI(retrieveSubjectPreferences, params)
    const handleChange = useCallback(({ subject, description }) => {
        updateSubjectPreferences({ ni, subject, description });
        setResult({ ...value, preferences: value.preferences.map(d => d.subject.slug !== subject.slug ? d : ({ ...d, description })) })
    }, [value])

    return <Info.Container modify={true} className="my-8">
        <InfoSectionHeading mediumTitle={t('subject-preferences')} description={t('student-subject-preferences-description')} />
        {value && value.preferences && value.preferences.filter(d => d.description && d.description !== "none").length > 2 && <RawTextWarning>{t('student-subject-preferences-warning')}</RawTextWarning>}
        <SelectPreferences
            values={value && value.preferences}
            setValue={handleChange}
            formatValue={(d) => d.subject.name}
            options={subjectPreferenceOptions}
            defaultOption={subjectPreferenceOptions.none} />
    </Info.Container>
}

function ExistingDemands({ demands, student }) {
    // const ni = student.ni;
    const { t } = useTranslation('common');
    const [period, setPeriod] = useState(getFullCurrentPeriod());
    const [open, setOpen] = useState(null);
    const latestDemandId = demands.length > 0 ? demands.sort((a, b) => dateParse(b.date) - dateParse(a.date))[0].id : null;
    const filteredDemands = demands.filter(d => period ? d.period === period.slug : true);
    // Sort by date
    const sortedDemands = filteredDemands.sort((a, b) => dateParse(b.date) - dateParse(a.date));
    const handleDelete = console.log;
    return <div className='border p-3 rounded-md'>
        {/* {error ? <Error.Text {...error} /> : null} */}
        <div className='flex items-center justify-between'>
            <SelectPeriods value={period} setValue={setPeriod} />

        </div>
        <div className='mt-2'>
            {filteredDemands.length === 0 && <p className='text-gray-500'>{t('no-pairing-demand')}</p>}
            {sortedDemands.map((row, i) =>
                <div key={i} className="flex justify-between gap-3 group p-2 rounded-md hover:bg-gray-50 ">
                    <span>{baseDayFormat(dateParse(row.date))} {row.inactive ? `(fermée le ${baseDayFormat2(dateParse(row.inactive))})` : ""}</span>
                    <div>{latestDemandId === row.id && (student.demand_for_tutoring) ?
                        <span className="text-sm text-green-500 bg-green-100 px-3 py-0.5 rounded-md inline">{t('active-fem')}</span> :
                        <span className='text-sm text-red-400 bg-red-100 px-3 py-0.5 rounded-md inline'>{t('inactive-fem')}</span>}</div>
                </div>)}
        </div>

        <Modal open={open ? true : false} setOpen={setOpen} >
            <ModalBasic title={t('delete-pairing-demand.title')}
                description={t('delete-pairing-demand.description')}
                submitLabel={t('delete-pairing-demand.confirm')}
                Icon={BellAlertIcon}
                onClick={handleDelete} />
        </Modal>
    </div>
}

function ControlDemandTutoring({ ni, execute, value, demands }) {
    const { t } = useTranslation('common');
    const latestDemandDate = demands.length > 0 ? demands.sort((a, b) => dateParse(b.date) - dateParse(a.date))[0].date : null;
    return <div className='flex flex-col justify-end my-3'>
        {value ?
            <span className="text-gray-600 mb-3 bg-gray-100 px-3 py-2 rounded-md"><FiCheck className="inline mr-3 text-green-500" />
                {t('tutoring-demand-is-active')}</span> :
            <span className="text-yellow-600 mb-3 bg-yellow-100 px-3 py-2 rounded-md">{t('tutoring-demand-is-inactive')}</span>}
        {!value ? <SelectDate value={null}
            label={t("demand-for-tutoring.btn")}
            xsDescription={latestDemandDate ? t("select-demand-for-tutoring-help", { date: baseDayFormat(dateParse(latestDemandDate)) }) : null}
            includeDescriptionInDropdown={true}
            setValue={(demandForTutoring) => {
                execute({ ni, demandForTutoring: demandForTutoring ? demandForTutoring : "" });
            }} /> :
            <Button onClick={() => execute({ ni, demandForTutoring: "" })} size="sm" color="delete">{t("deactivate-tutoring-demand")}</Button>
        }

    </div>
}


export function StudentPreferences() {
    const { student, setStudent } = useContext(StudentContext);
    const { t } = useTranslation('common');
    const ni = student.ni;
    const period = getCurrentPeriod();
    const [, { error, execute }] = useAPI(updateStudent, {}, { immediate: false, onResult: setStudent })

    if (!student.name) {
        return <Skeleton className="h-36 w-full" />
    }

    return <div className={classnames("space-y-8 pb-16")}>
        <div>
            {error ? <Error.Text {...error} /> : null}
            <div>
                <LargeLabel>{t("demand-for-tutoring.label", { period })}</LargeLabel>
                <SubLabel>{t("demand-for-tutoring.subLabel")}</SubLabel>
                <ControlDemandTutoring ni={ni} execute={execute} value={student.demand_for_tutoring} demands={student.pairing_demands || []} />
            </div>

            <ExistingDemands demands={student.pairing_demands || []} student={student} setStudent={setStudent} />
        </div>
        <ModifiableSelectField
            Field={CheckBox}
            label={t("student-has-been-met-and-discuss-for-tutoring.label")}
            subLabel={t("student-has-been-met-and-discuss-for-tutoring.subLabel")}
            modify={true}
            marker="select"
            value={student.tutoring_meeting_and_validation || false}
            setValue={
                (tutoringMeetingAndValidation) => {
                    execute({ ni, tutoringMeetingAndValidation });
                    setStudent(d => ({ ...d, tutoring_meeting_and_validation: tutoringMeetingAndValidation }))
                }
            } />


        <ModifiableSelectField
            Field={CheckBox}
            label={t("prioritary-for-tutoring.label")}
            subLabel={t("prioritary-for-tutoring.subLabel")}
            modify={true}
            marker="select"
            value={student.prioritary_for_tutoring || false}
            setValue={
                (prioritaryForTutoring) => {
                    execute({ ni, prioritaryForTutoring });
                    setStudent(d => ({ ...d, prioritary_for_tutoring: prioritaryForTutoring }))
                }
            } />
        <SelectSubjectPreferences ni={ni} />


        <Info.Container modify={true} className="my-8" >
            <InfoSectionHeading mediumTitle={t('availabilities')} />
            <SelectWeeklyAvailabilities
                value={student.schedule_availabilities}
                setValue={
                    (scheduleAvailabilities) => {
                        execute({ ni, scheduleAvailabilities: scheduleAvailabilities.map(e => [e.from_cron, e.to_cron]) });
                        setStudent(d => ({ ...d, schedule_availabilities: scheduleAvailabilities }))
                    }
                } />
        </Info.Container>


        <Info.Container modify={true} className="my-8">
            <InfoSectionHeading mediumTitle={t("tutoring-preference")} />
            <ModifiableSelectField
                Field={CheckBox}
                label={t("online-preference")}
                modify={true}
                value={student.online_preference || false}
                setValue={
                    (onlinePreference) => {
                        execute({ ni, onlinePreference });
                        setStudent(d => ({ ...d, online_preference: onlinePreference }))
                    }
                } />
            <ModifiableSelectField
                Field={CheckBox}
                label={t("hybrid-preference")}
                modify={true}
                value={student.hybrid_preference || false}
                setValue={
                    (hybridPreference) => {
                        execute({ ni, hybridPreference });
                        setStudent(d => ({ ...d, hybrid_preference: hybridPreference }))
                    }
                } />
            <ModifiableSelectField
                Field={CheckBox}
                label={t("on-site-preference")}
                modify={true}
                value={student.on_site_preference || false}
                setValue={
                    (onSitePreference) => {
                        execute({ ni, onSitePreference });
                        setStudent(d => ({ ...d, on_site_preference: onSitePreference }))
                    }
                } />
            <ModifiableSelectField
                Field={CheckBox}
                label={t("accept-triade.label")}
                subLabel={t("accept-triade.subLabel")}
                modify={true}
                marker="select"
                value={student?.no_triade ? false : true}
                setValue={
                    (acceptTriade) => {
                        execute({ ni, noTriade: !acceptTriade });
                        setStudent(d => ({ ...d, no_triade: !acceptTriade }))
                    }
                } />
        </Info.Container>




    </div>
}

export function PreferenceCell({ preference, on_site_preference, online_preference, hybrid_preference }) {
    const { t } = useTranslation("autoPairing");
    if (!preference && !on_site_preference && !online_preference && !hybrid_preference) return null;
    return <div className="whitespace-nowrap">
        {(on_site_preference || preference == "en-personne") &&
            <p className="flex items-center text-sm space-x-2 text-gray-700"><FiUsers className='shrink-0' /> <span className=''>{t("preferences.onsite")}</span></p>}
        {(online_preference || preference == "en-ligne") &&
            <p className="flex items-center text-sm space-x-2 text-green-600"><FiWifi className='shrink-0' /> <span className=''>{t("preferences.online")}</span></p>}
        {(hybrid_preference || preference == "hybride") &&
            <p className="flex items-center text-sm space-x-2 text-pink-600"><FiUsers className='shrink-0' /><FiWifi className='shrink-0' /> <span className=''>{t("preferences.hybrid")}</span></p>}
    </div>
}