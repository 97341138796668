/* eslint-disable */
import React, { useMemo } from 'react'

// Components
import { PairingContext } from 'contexts/pairing';
import { Info } from 'components/core/info';
import { FiLock, FiUserMinus } from 'react-icons/fi';
import { Error, InfoSectionHeading } from 'components/core/typo';
import { APITrigger } from 'components/core/trigger';
import { PairingGroupContextProvider, PairingGroupContext } from 'contexts/pairing-group';
import { Modal, ModalBasic, ModalBody } from 'components/core/modal';
import { SearchPairings } from 'components/tools/search_pairing';
import { CommonAvailability, CommonSubjects } from 'components/tools/new_pairing';

// API
import { deletePairingGroup, updatePairingGroup, createPairingGroup } from 'api/pairings';

// Utils
import classNames from 'classnames';
import { subjectsIntersections } from 'utils/subjects';

// Hooks
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'components/core/skeleton';
import { Button } from 'components/core/button';
import { useAPI } from 'hooks/useAPI';
import { Link } from 'react-router-dom';

export function PairingGroupsSettingsWithContext() {
    const { t } = useTranslation('common');
    const  { group, loading } = useContext(PairingGroupContext);
    return <>
        <h1 className="info-section">{t('pairing-triades-settings')}</h1>
        {
            loading && !group ? <Skeleton className="h-80" /> :
                group ? <div>
                    <ActiveGroup />
                    <Info.Container modify={true} className="!my-12">
                        <DeleteButton />
                    </Info.Container>
                </div> : 
                <EmptyGroup />
        }
    </>
}

export function PairingGroupsSettings(){
    return <>
        <PairingGroupContextProvider>
            <PairingGroupsSettingsWithContext />
        </PairingGroupContextProvider>
    </>
}

export function ActiveGroup() {
    const { t } = useTranslation('common');
    const { group } = useContext(PairingGroupContext);
    const { pairing } = useContext(PairingContext);
    const pairings = group?.pairings?.sort((a,) => a.id === pairing?.id ? -1 : 1);
    return <>
        <Info.Container description={t("active-triade")}>
            <Info.Field label={t("created-at")} value={new Date(group?.createdAt || "").toLocaleDateString()} />
        </Info.Container>

        {
            pairings?.map(({ id, student, tutor }, index) => {
                return <div key={id} className='mt-2'>
                    <Info.Container description={<Link to={`/pairings/${id}`}>{`${t("pairing")} ${index + 1}`}</Link>}>
                        <Info.Field label={t("student")} value={student.name} />
                        <Info.Field label={t("tutor")} value={tutor.name} />
                    </Info.Container>
                </div>
            })
        }
        
    </>
}

export function RemoveFromGroupButton({id, student, tutor}) {
    const { t } = useTranslation('common');
    const [open, setOpen] = useState(false);
    const { setGroup } = useContext(PairingGroupContext);
    const handleResult = (result)=> {
        setGroup?.(result)
    }
    const [,{execute, loading}]= useAPI(updatePairingGroup,{pairingId: id, group:null}, {immediate: false, onResult: handleResult, camelize: true});
    return <>
        <Button onClick={()=>setOpen(prev=>!prev)} color="inlineLink" size="xs">{t("remove-from-triade")}</Button>
        <Modal open={open} setOpen={setOpen}>
            <ModalBasic
                Icon={FiUserMinus}
                title={t("remove-pairing-from-triade.title")}
                disabled={loading || !id}
                submitLabel={t("remove-pairing-from-triade.button")}
                onClick={execute}
            >
                <ModalBody>
                    <div>
                        {t("pairing")} {student.name} & {tutor.name}
                    </div>
                    {t("remove-pairing-from-triade.description")}
                </ModalBody>
            </ModalBasic>
        </Modal>
    </>
}

export function EmptyGroup() {
    const { t } = useTranslation('common');
    return <>
        <Info.Container description={t("active-triade")} sideHeader={<CreateGroupButton/>}>
            <Info.Field noValueLabel={t("no-active-triade")} />
        </Info.Container>
    </>
}

export function CreateGroupButton(){
    const { t } = useTranslation('common');
    const [open, setOpen] = useState(false);
    const { pairing, loading:loadingPairing } = useContext(PairingContext);
    const { id, student, tutor } = pairing || {};
    const [otherPairing, setOtherPairing] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const isSamePairing = otherPairing?.id === id;
    const cronsPairingA = pairing?.period;
    const cronsPairingB = otherPairing?.period;
    const subjectsA = pairing?.subjects || [];
    const subjectsB = otherPairing?.subjects || [];
    const isSamePeriod = cronsPairingA === cronsPairingB;
    const commonSubjects = subjectsIntersections(subjectsA, subjectsB);
    const filterResults = (results) => results?.filter(({id}) => id !== pairing?.id);
    return <>
        <Button color="active" size="md" onClick={()=>setOpen(true)}>
            {t("create-triade")}
        </Button>
        <Modal open={open} setOpen={setOpen}>
            <div className='space-y-4'>
                <InfoSectionHeading title={t("create-triade")} noMargin/>
                <Info.Container description={<Link to={`/pairings/${id}`}>{`${t("pairing")} 1`}</Link>}>
                    <Info.Field label={t("student")} value={student.name} />
                    <Info.Field label={t("tutor")} value={tutor.name} />
                </Info.Container>
                <Info.Container description={<Link to={`/pairings/${id}`}>{`${t("pairing")} 2`}</Link>}>
                    <SearchPairings 
                        value={otherPairing} 
                        setValue={setOtherPairing} 
                        extraParams={{triade:false, school: student?.school}} 
                        tutor={tutor?.ni} 
                        filterResults={filterResults}
                    />
                </Info.Container>
                {isSamePairing && <Error.Text detail={t("triade-with-same-pairing")} />}
                {!isSamePairing && otherPairing && <CommonAvailability common_availability={isSamePeriod? [cronsPairingA]:[]} />}
                {!isSamePairing && otherPairing && <CommonSubjects common_subjects={commonSubjects} />}
                <CreateGroupTrigger pairings={[id, otherPairing?.id]} setLoading={setLoading} setError={setError} onResult={()=>setOpen(false)}>
                    <Button disabled={!pairing || !otherPairing || loadingPairing || loading || isSamePairing || !isSamePeriod || commonSubjects?.length === 0} loading={loading} color="active" size="lg" block >
                        {t('add')}
                    </Button>
                </CreateGroupTrigger>
                {error? <Error.Text {...error}/>: null}
            </div>
        </Modal>
    </>
}

export function CreateGroupTrigger({children, pairings, onResult, setLoading, setError}){
    const { setGroup } = useContext(PairingGroupContext);
    const { setPairing } = useContext(PairingContext);
    const handleResult = (result) => {
        onResult?.(result)
        setGroup?.(result)
        setPairing?.(pairing => ({...pairing, group: result?.id}))
    }
    return <APITrigger 
        method={createPairingGroup}
        params={{
            pairings
        }}
        options={{
            immediate: false,
            onResult: handleResult,
            camelize: true
        }}
        setLoading={setLoading}
        setError={setError}
    >
        {children}
    </APITrigger>
}

export function DeleteButton({className}) {
    const { t } = useTranslation('common');
    const [lock, setLock] = useState(true);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    return <div className={classNames("relative", className)}>
        {lock && <div className="absolute z-20 flex items-center justify-center w-full h-full text-center">
            <div>
                <h3 className="pb-3 text-lg text-gray-800">{t("delete-triade.title")}</h3>
                <Button color="deleteContrast" size="lg" onClick={() => setLock(false)}><FiLock /> {t("dissociate")}</Button>
            </div>
        </div>}
        {lock && <div className='absolute z-10 bg-gray-100/90 w-full h-full rounded-lg' />}
        <div className={classNames(" rounded-lg p-3 w-full space-y-3", lock ? " " : "border-2 border-red-500")}>
            <div>
                <h3 className="font-medium text-red-600">{t("delete-triade.title")}</h3>
                <p className="text-gray-500">{t("delete-triade.description")}</p>
            </div>
            <DeleteGroupTrigger setLoading={setLoading} setError={setError}>
                <Button disabled={lock || loading} loading={loading} block color="deleteContrast" size="lg" className="whitespace-nowrap">{t("delete-triade.button")}</Button>
            </DeleteGroupTrigger>
            {error ? <Error.Text {...error} /> : null}
        </div>
    </div>
}

export function DeleteGroupTrigger({children, groupId, onResult, setLoading, setError}){
    const { group, setGroup } = useContext(PairingGroupContext);
    const { setPairing } = useContext(PairingContext);
    const handleResult = (result) => {
        setPairing?.(pairing => ({...pairing, group: null}))
        setGroup?.(null)
        onResult?.(result)
    }
    return <>
        <APITrigger 
            method={deletePairingGroup}
            params={{
                groupId: group?.id || groupId
            }}
            options={
                {
                    immediate: false,
                    onResult: handleResult
                }
            }
            setLoading={setLoading}
            setError={setError}
        >
            {children}
        </APITrigger>
    </>
}