/* eslint-disable */

import React from "react";

// Components
import { Button } from "components/core/button";
import { Dropdown } from "components/core/dropdown"
import { CreateDocumentInModal } from "components/documents/create_modal";
import { CreateNoteInModal } from 'components/notes/create_modal';
import { TeamContext } from "contexts/team";
import { useSearchParams } from "react-router-dom";

// Utils
import classNames from "classnames";
import { getStudentStatusFromNotes } from "components/follow-up/meetings/status";
import { recursiveCamelize } from "utils/casing";

// Icon
import { FiInfo as InfoIcon } from "react-icons/fi";
import { FiAlertCircle as AlertIcon } from "react-icons/fi";

// Hooks
import { useTranslation } from "react-i18next";
import { Skeleton } from "components/core/skeleton";
import { useState } from "react";
import { useStudentsMeetings } from "contexts/student-meetings";
import { useContext, useMemo } from "react";

function extractDocumentInitials(name) {
    let initials = ""
    if (name.toLowerCase().includes("bulletin")) initials += "B"
    else if (name.toLowerCase().includes("feuille")) initials += "F"
    else {
        const firstLetter = name[0] || ""
        initials += firstLetter.toUpperCase()
    }
    if (name.toLowerCase().includes("final")) initials += "F"

    if (!initials) return undefined
    const number = name?.match(/\d+/)
    if (number && initials != "BF") initials += number[0]
    return initials
}

export function DocumentsLegend() {
    const { t } = useTranslation("common");

    return <>
        <div className="flex flex-col gap-1 text-sm font-normal text-black tracking-normal normal-case leading-none">
            <div className="flex items-center gap-1.5">
                <div className=""><AlertIcon className="fill-blue-500 text-white size-4 shrink-0 -ml-0.5" /></div>
                <div className="">{t("not-received-after-limit")}</div>
            </div>
            <div className="flex items-center gap-1.5">
                <div className="bg-gray-300 size-3 shrink-0 rounded-full"></div>
                <div className="">{t("not-received")}</div>
            </div>
            <div className="flex items-center gap-1.5">
                <div className="bg-black size-3 shrink-0 rounded-full"></div>
                <div className="">{t("received-without-note")}</div>
            </div>
            <div className="flex items-center gap-1.5">
                <div className="bg-green-500 size-3 shrink-0 rounded-full"></div>
                <div className="">{t("students-metrics.status.success")}</div>
            </div>

            <div className="flex items-center gap-1.5">
                <div className="bg-yellow-500 size-3 shrink-0 rounded-full"></div>
                <div className="">{t("students-metrics.status.at-risk")}</div>
            </div>
            <div className="flex items-center gap-1.5">
                <div className="bg-red-500 size-3 shrink-0 rounded-full"></div>
                <div className="">{t("students-metrics.status.fail")}</div>
            </div>
        </div>
    </>
}

export function Documents({ student, documents, documentsGroups, loading, className }) {
    return <>
        <div className={classNames(className,)}>
            <DocumentsList
                student={student}
                documents={documents}
                groups={documentsGroups}
                loading={loading}
            />
        </div>
    </>
}


export function DocumentsList({ student, documents, groups, loading }) {
    return <>
        <div className="flex gap-1 items-center">
            {
                loading && <>
                    <Skeleton className="size-7 border-2 border-transparent !rounded-full" />
                    <Skeleton className="size-7 border-2 border-transparent !rounded-full" />
                    <Skeleton className="size-7 border-2 border-transparent !rounded-full" />
                    <Skeleton className="size-7 border-2 border-transparent !rounded-full" />
                    <Skeleton className="size-7 border-2 border-transparent !rounded-full" />

                </>
            }
            {
                !loading && groups?.map(({ group, shouldBeReceivedAt, id }) => {
                    const code = group?.code;
                    const matchingDocuments = documents?.filter(document => document?.group?.code === code) || [];
                    const { status } = getStudentStatusFromNotes(matchingDocuments?.map(d => d?.notes).flat() || []);
                    const initials = group?.initials || extractDocumentInitials(group?.name || "");
                    if (!initials) return null
                    return <Document
                        key={id}
                        student={student}
                        initials={initials}
                        status={status}
                        matchingDocuments={matchingDocuments}
                        documents={documents}
                        group={group}
                        shouldBeReceivedAt={shouldBeReceivedAt}
                    />
                })
            }
        </div>

    </>
}

function Badge({ href, initials, status, handedOver, warnNotReceived, className, ...props }) {

    const success = status === "success"
    const fail = status === "fail"
    const atRisk = status === "at-risk"
    return <>
        <div {...props} className={classNames(className,
            "group relative flex flex-wrap tabular-nums items-center justify-center rounded-full border-2 h-7 min-w-7 px-1 text-sm",
            success ? "border-green-500 bg-green-500 text-white" :
                fail ? "border-red-500 bg-red-500 text-white" :
                    atRisk ? "text-white border-yellow-500 bg-yellow-500" :
                        handedOver ? "text-black border-black" :
                            "text-gray-300 border-gray-300 cursor-pointer")}>
            {initials}
            {
                warnNotReceived && <div className="absolute top-0 left-full -translate-x-2/3 -translate-y-1/2">
                    <AlertIcon className="text-white fill-blue-500 text-lg" />
                </div>
            }
        </div>

    </>
}

export function Document({ student, initials, status, matchingDocuments, shouldBeReceivedAt, documents, group }) {
    const { t } = useTranslation("common");

    const handedOver = matchingDocuments?.map(document => document?.handedOverAt).filter(Boolean).length > 0
    const warnNotReceived = shouldBeReceivedAt ? !handedOver && new Date(shouldBeReceivedAt || "") < new Date() : false
    const noStatusNote = handedOver && !status

    const [openAddDocument, setOpenAddDocument] = useState(false)
    const [openAddNote, setOpenAddNote] = useState(false)
    const { setStudents } = useStudentsMeetings()
    const [searchParams, setSearchParams] = useSearchParams()
    const hasNoteOrDocParams = searchParams.has("noteId") || searchParams.has("documentId")
    const { team, loading: loadingTeam } = useContext(TeamContext);
    const { teamPeriod } = useMemo(() => {
        const v = recursiveCamelize(team);
        return { start: v?.activePeriod?.start, end: v?.activePeriod?.end, teamPeriod: v?.activePeriod?.slug }
    }, [team])
    const handleClick = () => {
        if (handedOver && !noStatusNote) return setSearchParams({})
        if (noStatusNote) return setSearchParams({ documentId: matchingDocuments[0]?.id })
        setOpenAddDocument(true)
    }
    const setDocuments = (documents) => {
        setStudents((prev) => prev.map(s => s.ni === student.ni ? { ...s, documents } : s))
    }
    const handleCreateDocumentNote = (note) => {
        const { code } = note?.code
        note.code = code || note?.code
        const noteWithGroup = { ...note, group: group };
        setStudents(prev =>
            prev.map(s => {
                if (s.ni === student?.ni) {
                    const document = s.documents?.find(d => d.group?.code === group?.code);
                    if (!document) return s;
                    const updatedDocument = {
                        ...document,
                        notes: [...(document.notes || []), noteWithGroup]
                    };
                    const updatedDocuments = s.documents.map(d => d.group?.code === group?.code ? updatedDocument : d);
                    return {
                        ...s,
                        documents: updatedDocuments
                    };
                }
                return s;
            })
        );
    };
    if (loadingTeam) return <></>
    return <>
        <Dropdown
            vOrientation="up"
            itemClassName="w-[200px]"
            menuItemsClassName="!-translate-y-[32px] bottom-0"
            customButtonClassName={classNames((!matchingDocuments || matchingDocuments.length === 0) ? "cursor-default" : "")}
            disabled={(!matchingDocuments || matchingDocuments.length === 0) || noStatusNote || hasNoteOrDocParams} withChevron={false}
            customButton={

                <Badge
                    handedOver={handedOver}
                    warnNotReceived={warnNotReceived}
                    status={status}
                    initials={initials}
                    onClick={handleClick}
                />
            }
        >

            <Dropdown.Info>
                <Button color="hiddenLink" href={matchingDocuments && matchingDocuments.length > 0 && `?documentId=${matchingDocuments[0]?.id}`}>{group?.name}</Button>
                {
                    matchingDocuments?.map((document, index) => {
                        const formattedDate = new Date(document?.handedOverAt).toLocaleDateString()
                        const notes = document?.notes || [];
                        return <div key={`-${index}-${document.id}`} className="flex flex-col ">
                            <span className="text-gray-500 text-sm">{t("handed-over-at")} {formattedDate}</span>
                            <div className="flex flex-col">

                                {
                                    notes.map((note, i) => {
                                        const formattedDate = new Date(note?.openedAt).toLocaleDateString()
                                        const { status } = getStudentStatusFromNotes([note])
                                        if (!status) return null
                                        return <Button color={"hiddenLink"} href={`?noteId=${note?.id}`} key={`${note?.id}-${i}`} className={classNames("text-sm", status === "success" ? "text-green-500" : status === "fail" ? "text-red-500" : status === "at-risk" ? "text-yellow-500" : "text-gray-500")}>
                                            {formattedDate} - {t("students-metrics.status." + status)}
                                        </Button>
                                    })
                                }
                                {
                                    noStatusNote && <Button color={"inlineLink"} onClick={() => setOpenAddNote(true)}>
                                        {t("add-note")}
                                    </Button>
                                }
                            </div>
                        </div>
                    })
                }

            </Dropdown.Info>
        </Dropdown>
        <CreateDocumentInModal
            open={openAddDocument}
            setOpen={setOpenAddDocument}
            creatorProps={{ defaultStudent: student, defaultPeriod: { slug: teamPeriod, name: teamPeriod }, defaultGroup: group, defaultHandedOverAt: new Date() }}
            onCompleted={(doc) => {
                if (!doc) return
                if (doc?.period?.slug !== teamPeriod) return
                const newDocuments = [...(documents || []), doc]
                setDocuments(newDocuments)
            }}
        />
        <CreateNoteInModal
            open={openAddNote}
            setOpen={setOpenAddNote}
            creatorProps={{
                defaultStudent: student,
                defaultPeriod: { slug: teamPeriod, name: teamPeriod },
                defaultGroup: group,
                onResult: handleCreateDocumentNote
            }}
        />
    </>
}

